import axios from "axios";
// import { REFRESH_TOKEN_API } from "../../constants/endpoints/auth";
import { BASE_URL } from "../baseUrl";
// import { LOGIN_ROUTE } from "../../routes";
import { clearCurrentUser } from "../../util/localStorarge";
// import { history } from 
// import {history} from '../../index.js'

const appAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
  },
});

// Add a request interceptor
appAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.access_tk;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    console.log(error);
  }
);

appAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if(error.response.status === 401){
      clearCurrentUser();
      window.location = "/login"
    }
    // const originalRequest = error.config;
   
    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url === REFRESH_TOKEN_API
    // ) {
    //   history.push(LOGIN_ROUTE);
    //   return Promise.reject(error);
    // }
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;

    //   const { data } = await appAxios.get(REFRESH_TOKEN_API);
    //   const { token } = data;
    //   if (token) {
    //     localStorage.setItem("access_tk", token);
    //     originalRequest.headers["Authorization"] = `Bearer ${token}`;
    //     return appAxios(originalRequest);
    //   } else {
    //     localStorage.removeItem("access_tk");
    //     history.push(LOGIN_ROUTE);
    //   }
    // }

    return Promise.reject(error);
  }
);

export default appAxios;
