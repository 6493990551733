import { combineReducers } from "redux";
import loginReducer from "../views/LOGIN/redux/loginReducer";
import settingsReducer from "../views/SETTINGS/redux/settingsReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  settings: settingsReducer,
});

export default rootReducer;
