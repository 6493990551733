// import { BsTelephone } from "react-icons/bs";
// import { IoLogoWhatsapp } from "react-icons/io";
// import { RiInstagramFill } from "react-icons/ri";
// import { FaFacebook } from "react-icons/fa";
import "./footer.css";
const Footer = () => {
  // const currentYear = new Date().getFullYear();
  return (
    <footer>
      
      <div>
      <p>7B Etim Inyang Crescent, Victoria Island, Lagos, Nigeria.</p>
      <div className="phone"> 
      {/* <BsTelephone /> */}
        <p>+234 1 633 7068</p>
      </div>
      <p className="email">hello@MILIKI.ng</p>
      </div>
      {/* <ul>
        <li>
          <FaFacebook />
        </li>
        <li>
          <BsTwitter />
        </li>
        <li>
          <RiInstagramFill />
        </li>
        <li>
          <IoLogoWhatsapp />
        </li>
      </ul> */}
      <div>
        {/* &copy; */}
        <p>
          <a href='https://miliki.ng/'>
            MILIKI.ng
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
