import { getCurrentUser } from "../../../util/localStorarge";
import { LOGIN_ACTION, TOGGLE_LOGOUT } from "./loginTypes";

const INITIAL_STATE = {
  isAuthenticated: !!getCurrentUser(),
  currentUser: getCurrentUser(),
  showLgoutModal: false
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_ACTION:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload,
      };
      case TOGGLE_LOGOUT:
        return {
          ...state,
          showLgoutModal: !state.showLgoutModal
        };
    default:
      return { ...state };
  }
};

export default authReducer;
