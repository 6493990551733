import Footer from "../../components/FOOTER/Footer";
import MessageBanner from "../../components/MESSAGEBANNER/MessageBanner";
import "./passwordReset.css";

const PasswordReset = () => {
  const handleVerification = () => {};
  return (
    <div className="password-reset">
      <MessageBanner
        heading="RESET YOUR PASSWORD"
        greeting="Hello John,"
        message="A request to reset your MILIKI password has been made. If you did not make this request, simply ignor this email. If you did make this request, please reset your password"
        note="If the button above did not work, try copying the URL into your browser. If you continue to have this problem, please contact us at admin@miliki.ng"
        actBtnText="RESET PASSWORD"
        actBtnHandler={handleVerification}
      />
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default PasswordReset;
