import Footer from "../../components/FOOTER/Footer";
import MessageBanner from "../../components/MESSAGEBANNER/MessageBanner";
import "./passwordResetSuccess.css";

const PasswordResetSuccess = () => {
  const handleVerification = () => {};
  return (
    <div className="password-reset-success">
      <MessageBanner
        heading="You've Got Yourself a New Password"
        greeting="Hello John,"
        message="Congratulations! Your password has been changed successfully"
        note=""
        actBtnText="HELP! I DIDN'T REQUEST THIS"
        actBtnHandler={handleVerification}
      />
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default PasswordResetSuccess;
