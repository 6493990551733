import moment from "moment";
export const parseDate = (value) => {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD");
    // const date = new Date(value)
    // const year = date.getFullYear()
    // const month = date.getMonth()
    // const day = date.getDate()
    // console.log(date, year, month, day, "jjjjd", value)
    // return '2022-05-20'
    // return `${year}-${month}-${day}`;
    // if (typeof value === "string" && String(value).includes("/")) {
    //   if (value.split("/")[0]?.length < 4) {
    //     const [day, month, year] = value.split("/");
    //     return `${year}-${month}-${day}`;
    //   } else {
    //     const [year, month, day] = value.split("/");
    //     const cleanedDay = day.length > 2 ? day.substring(0, 3).trim() : day;
    //     return `${year}-${month}-${cleanedDay}`;
    //   }
    // } else if (typeof value === "string" && String(value).includes("-")) {
    //   if (value.split("-")[0]?.length < 4) {
    //     const [day, month, year] = value.split("-");
    //     return `${year}-${month}-${day}`;
    //   } else {
    //     const [year, month, day] = value.split("-");
    //     const cleanedDay = day.length > 2 ? day.substring(0, 3).trim() : day;
    //     return `${year}-${month}-${cleanedDay}`;
    //   }
    // } else {
    //   return "";
    // }
  }
  return "";
};

export const nairaSymbol = "₦";
export const formatCurrency = (num = 0) => {
  const numb = Number(num);
  return String(numb.toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
export const cleanNumber = (number) => {
  if (String(number).includes(",")) {
    return String(number).split(",").join("");
  }
  return number;
};
export const csvToArray = (str, delimiter = ",") => {
  let titles = str.slice(0, str.indexOf("\n")); //.split(delimiter);
  //
  return titles.split(delimiter);
};

export const truncateWords = (word, length) => {
  if (word.length <= length) return word;

  const truncateWord = `${word.substring(0, length)}`;

  return truncateWord.concat("...");
};
export const stringToBoolean = (value) => {
  return value === "YES";
};
export const booleanToString = (value) => {
  return value ? "YES" : "NO";
};
export const getEventTime = (date) => {
  if (!date) return "";
  const theDate = moment(new Date(date));
  const hours = theDate.hour(),
    mins = theDate.minutes();

  const time = `${hours}:${mins}`;
  return time;
};

export const isPastDate = (date) => {
  if (!date) return true;
  return moment(new Date()) > moment(new Date(date));

  // const now = new Date();
  // const theDate = new Date(parseDate(date));

  // if (theDate < now) return true;

  // return false;
};
export const isLessThan = (age = 25, date) => {
  if (!date) return true;

  const now = new Date();
  const theDate = moment(new Date(date)).year();
  const nowMoment = moment(now).year();
  return nowMoment - theDate < age;

  // return false;
};
export const setEventTime = (date, eventDateTime = "") => {
  if (!date) return "";

  const theDate = new Date(date);
  theDate.setHours();
  theDate.setMinutes();
  const dashedDate = parseDate(date);
  // const theDate = new Date(parseDate(date));
  if (!eventDateTime) return `${dashedDate} 00:00`;
  const eventDate = new Date(eventDateTime);

  return `${dashedDate} ${getDoubleValuDate(
    eventDate.getHours()
  )}:${getDoubleValuDate(eventDate.getMinutes())}`;
  // theDate.setTime(eventDate.getTime());
  // return theDate.toISOString();
};

export const getParseTime = (date) => {
  if (!date) return "";
  return get12HoursTimeFromDateTime(date);
};
export const formatDate = (date) => {
  if (!date) return "";
  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const theDate = new Date(date);
  const month = MONTHS[theDate.getMonth()];
  const year = theDate.getFullYear();
  const dateVal = theDate.getDate();
  if (!month || !year || !dateVal) return "";
  return `${month || ""} ${dateVal || ""}, ${year || ""}`;
};

export const hasError = (error = {}) => {
  return Object.keys(error).length;
};
export const getDoubleValuDate = (date) => {
  return String(date).length < 2 ? `0${date}` : date;
};

export const dashedDateFormat = (date) => {
  if (!date) return "";

  const theDate = new Date(date);
  const dd = String(theDate.getDate()).padStart(2, "0");
  const mm = String(theDate.getMonth() + 1).padStart(2, "0");
  const yyyy = theDate.getFullYear();

  return `${yyyy}-${mm}-${dd}`;

  // const theDate = new Date(date);
  // const dashedDate = `${theDate.getFullYear()}-${getDoubleValuDate(
  //   theDate.getMonth() + 1
  // )}-${getDoubleValuDate(theDate.getDate())}`;
};

export const getFormData = (data = {}) => {
  const formData = new FormData();
  for (let key of Object.keys(data)) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

// get current week start date and end date
export const getWeekStartEndDate = () => {
  const today = new Date();
  const startDate = new Date(today.setDate(today.getDate() - today.getDay()));
  const endDate = new Date(today.setDate(today.getDate() - today.getDay() + 6));

  const startDateString = `${startDate.getFullYear()}-${getDoubleValuDate(
    startDate.getMonth() + 1
  )}-${getDoubleValuDate(startDate.getDate())}`;

  const endDateString = `${endDate.getFullYear()}-${getDoubleValuDate(
    endDate.getMonth() + 1
  )}-${getDoubleValuDate(endDate.getDate())}`;

  return { startDateString, endDateString };
};

// get current month start date and end date
export const getMonthStartEndDate = () => {
  var date = new Date();
  var startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  var endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const startDateString = `${startDate.getFullYear()}-${getDoubleValuDate(
    startDate.getMonth() + 1
  )}-${getDoubleValuDate(startDate.getDate())}`;

  const endDateString = `${endDate.getFullYear()}-${getDoubleValuDate(
    endDate.getMonth() + 1
  )}-${getDoubleValuDate(endDate.getDate())}`;

  return { startDateString, endDateString };
};

// get current year start and end date
export const getYearStartEndDate = () => {
  var date = new Date();
  var startDate = new Date(date.getFullYear(), 0, 1);
  var endDate = new Date(date.getFullYear(), 11 + 1, 0);

  const startDateString = `${startDate.getFullYear()}-${getDoubleValuDate(
    startDate.getMonth() + 1
  )}-${getDoubleValuDate(startDate.getDate())}`;

  const endDateString = `${endDate.getFullYear()}-${getDoubleValuDate(
    endDate.getMonth() + 1
  )}-${getDoubleValuDate(endDate.getDate())}`;

  return { startDateString, endDateString };
};
// get the current date month start and end date by passing the month number
export const getMonthStartEndDateByMonthNumber = (monthNumber) => {
  var date = new Date();
  var startDate = new Date(date.getFullYear(), monthNumber, 1);
  var endDate = new Date(date.getFullYear(), startDate.getMonth() + 1, 0);

  const startDateString = `${startDate.getFullYear()}-${getDoubleValuDate(
    startDate.getMonth() + 1
  )}-${getDoubleValuDate(startDate.getDate())}`;

  const endDateString = `${endDate.getFullYear()}-${getDoubleValuDate(
    endDate.getMonth() + 1
  )}-${getDoubleValuDate(endDate.getDate())}`;

  return { startDateString, endDateString };
};
// pluralizeName
export const pluralizeName = (name) => {
  if (name.toLowerCase().endsWith("s")) {
    return name + "es";
  } else {
    return name + "'s";
  }
};
// get 12 hours time
export const get12HoursTime = (hour, min) => {
  const today = new Date();
  today.setHours(hour);
  today.setMinutes(min);

  return moment(today).format("HH:mm A");

  // if(!hour) return "";
  // if (hour === 0 || String(hour) === "0") {
  //   return `12:${getDoubleValuDate(min)} AM`;
  // }
  // if (hour === 12) {
  //   return `12:${getDoubleValuDate(min)} PM`;
  // }
  // if (hour > 12) {
  //   return `${getDoubleValuDate(hour - 12)}:${getDoubleValuDate(min)} PM`;
  // } else {
  //   return `${getDoubleValuDate(hour)}:${getDoubleValuDate(min)} AM`;
  // }
};
export const get12HoursTimeFromDateTime = (dateTime) => {
  if (!dateTime) return "";

  return moment(new Date(dateTime)).format("hh:mm A");
  // const [hour, min] = dateTime ? getEventTime(dateTime)?.split(":") : ["", ""];

  // const time = get12HoursTime(hour, min);

  // return time;
};
export const getCurrentWeekTransData = (data = []) => {
  const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const values = {
    Sunday: 0,
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
  };

  for (let datum of data) {
    const day = new Date(datum.payment_date).getDay();
    const dayName = daysOfTheWeek[day];
    if (values[dayName]) {
      values[dayName] = values[dayName] + Number(datum.total_amount);
    } else {
      values[dayName] = Number(datum.total_amount);
    }
  }
  const valuesData = sortByDays(Object.entries(values));

  if (valuesData.length) {
    const vals = valuesData.map((entries) => entries[1]);
    const labels = valuesData.map(([key]) => key);
    return { labels, values: vals };
  } else {
    return {
      labels: daysOfTheWeek,
      values: [0, 0, 0, 0, 0, 0, 0],
    };
  }
};

// Group the array of transactions total amount by week of the current month
export const getCurrentMonthTransData = (data = []) => {
  const values = {
    Week1: 0,
    Week2: 0,
    Week3: 0,
    Week4: 0,
    Week5: 0,
  };

  for (let datum of data) {
    const paymentDate = new Date(datum.payment_date).getDate();

    if (paymentDate <= 7) {
      values["Week1"] = values["Week1"]
        ? values["Week1"] + Number(datum.total_amount)
        : Number(datum.total_amount);
    } else if (paymentDate == 8 || paymentDate <= 14) {
      values["Week2"] = values["Week2"]
        ? values["Week2"] + Number(datum.total_amount)
        : Number(datum.total_amount);
    } else if (paymentDate == 15 || paymentDate <= 21) {
      values["Week3"] = values["Week3"]
        ? values["Week3"] + Number(datum.total_amount)
        : Number(datum.total_amount);
    } else if (paymentDate == 22 || paymentDate <= 28) {
      values["Week4"] = values["Week4"]
        ? values["Week4"] + Number(datum.total_amount)
        : Number(datum.total_amount);
    } else if (paymentDate == 29 || paymentDate <= 31)
      values["Week5"] = values["Week5"]
        ? values["Week5"] + Number(datum.total_amount)
        : Number(datum.total_amount);
  }

  const labels = Object.keys(values);
  const valuesData = Object.values(values);

  return { labels, values: valuesData };
};

export const getYearData = (data = []) => {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const values = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };
  for (let datum of data) {
    const month = new Date(datum.payment_date).getMonth();
    if (values[MONTHS[month]]) {
      values[MONTHS[month]] =
        values[MONTHS[month]] + Number(datum.total_amount);
    } else {
      values[MONTHS[month]] = Number(datum.total_amount);
    }
  }
  const valuesData = sortByMonth(Object.entries(values));

  if (valuesData.length) {
    const vals = valuesData.map((entries) => entries[1]);
    const labels = valuesData.map(([key]) => key);
    return { labels, values: vals };
  } else {
    return {
      labels: MONTHS,
      values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  }
};


const sortByMonth = (dataTurple) => {
  const allMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return dataTurple.sort(
    (a, b) => allMonths.indexOf(a[0]) - allMonths.indexOf(b[0])
  );
};
const sortByDays = (dataTurple) => {
  const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return dataTurple.sort(
    (a, b) => daysOfTheWeek.indexOf(a[0]) - daysOfTheWeek.indexOf(b[0])
  );
};

export const addDaysToDate = (date, days) => {
  if (!date) return null;
  let theDate = new Date(date);
  theDate.setDate(theDate.getDate() + days);

  return theDate;
};
export const sortObject = (obj) => {
  return Object.entries(obj).sort((a, b) => b[0] - a[0]);
};

// PATTERNS
export const emailRegex = "^[^@]+@[^@]+.[^@]{2}[^@]*$"; // "^[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z]{n}$";
export const alphaNumericRegex = "^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$";
export const alphabetRegex = "^[A-Za-z]+(?: +[A-Za-z]+)*$";
//eslint-disable-next-line
export const phoneNumberRegex = "^[0-9-+]{9,15}$";
//eslint-disable-next-line
export const urlRegex =
  "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})";
