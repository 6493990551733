import { useHistory } from "react-router-dom";
import Button from "../../components/BUTTON/button";
import "./forgotPasswordSuccess.css";
// import LOGO from "../../assets/transparentLogo.png";
import { RESET_PASSWORD_ROUTE } from "../../routes";
import { Logo } from "../../assets";

const ForgotPasswordSuccess = () => {
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleReset = () => {
    history.push(RESET_PASSWORD_ROUTE);
  };
  return (
    <div className="forgot-passw-success">
      <div className="form-ctn">
        <form onSubmit={handleSubmit}>
          <div className="logo-ctn">
            <div className="logo-wrap">
              <img src={Logo} alt="logo" className="logo" />
            </div>
          </div>
          <h3 className="sm-text">Reset Password</h3>
          <h5>A token has been sent to your email to reset your password</h5>
          <p className="sub-title"> Click the button below to reset password</p>

          <div className="btn-container-lg">
            <Button
              btnCustomClassName="btn-email"
              innerTextBtn="Reset Password"
              handleSubmitAction={handleReset}
            />
          </div>

          {/* <div className="back-link-ctn">
            <Link to={FORGOT_PASSWORD_ROUTE} className="back-link">
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
              <span>GO BACK</span>
            </Link>
          </div> */}
        </form>
      </div>
      <div className="forgot-p-ctn">
        <div className="left-ctn"></div>
        <div className="right-ctn" />
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
