import { Logo } from "../../assets";
import Footer from "../../components/FOOTER/Footer";
import "./privacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="message-banner">
        <img src={Logo} alt="logo" />
        <div>
          <h1>Privacy Policy for MILIKI Application</h1>
          <p>Last Updated: [08/08/2023]</p>
          <p>
            MILIKI is committed to protecting the privacy of its users. This
            Privacy Policy explains how we collect, use, disclose, and safeguard
            your information when you use our mobile application MILIKI. Please
            read this Privacy Policy carefully. By using the Application, you
            agree to the practices described in this Privacy Policy.
          </p>
          <h6>1. Information We Collect</h6>
          <p>
            We may collect certain information from and about users of our
            application:
          </p>
          <br />
          <p>
            <strong>Information You Provide to Us</strong>: We may collect the
            information you provide directly, including but not limited to your
            name, email address, and any other information you provide when you
            register an account or otherwise interact with the Application.
          </p>
          <br />
          <p>
            <strong>Automatically Collected Information:</strong>When you access
            or use our Application, we may automatically collect certain
            information about you, including:
          </p>
          <ul>
            <li>
              Log Information: We may log information about your application
              use, including the browser type, access times, pages viewed, and
              IP address.
            </li>
            <li>
              Device Information: We may collect information about the device
              you use to access the Application, including the device type,
              operating system, and device identifiers.
            </li>
            <li>
              Usage Information: We may collect information about your usage of
              the Application, including the actions you take in the
              Application, the features you use, and the content you interact
              with.
            </li>
          </ul>
          <br />
          <h6>2.How We Use Your Information</h6>
          <p>
            We may use the information we collect from you for various purposes,
            including but not limited to:
          </p>

          <ul>
            <li>Provide, maintain, and improve the Application.</li>
            <li>Respond to your comments, questions, and requests.</li>
            <li>
              Send technical notices, updates, security alerts, and support
              messages.
            </li>
            <li>
              Monitor and analyse usage patterns and trends to enhance the user
              experience.
            </li>
            <li>
              Personalize and improve the content and features of the
              Application.
            </li>
          </ul>
          <br />
          <h6>3. Disclosure of Your Information</h6>
          <p>We may disclose your information as follows:</p>
          <br />
          <h6>With your consent.</h6>
          <ul>
            <li>To comply with legal obligations.</li>
            <li>To protect and defend our rights and property.</li>
            <li>In connection with a business transaction.</li>
          </ul>
          <br />
          <h6>4. Data Security</h6>
          <p>
            We take reasonable measures to protect the information we collect
            from loss, theft, misuse, unauthorised access, disclosure,
            alteration, and destruction. However, data transmission and storage
            systems can only be guaranteed 100% secure.
          </p>
          <br />
          <h6>5. Your Choices</h6>
          <p>
            You can review and update your account information by clicking on
            the settings option available on the MILIKI application and clicking
            on the profile information. There you can update your data as
            required.{" "}
          </p>
          <br />
          <h6>6. Changes to This Privacy Policy</h6>
          <p>
            We may update our Privacy Policy from time to time, and users will
            be notified about changes to our Privacy Policy via email and will
            be given a chance to accept the updates. This will constitute your
            consent to the updated policy.
          </p>
          <br />
          <h6>7. Contact Us</h6>
          <p>
            If you have any questions about our Privacy Policy or practices,
            please contact us at welcome@miliki.ng.
          </p>
        </div>
      </div>

      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
