import { useEffect, useState } from "react";

const useNetwork = () => {
  const [isNetwork, setIsNetwork] = useState(navigator.onLine);

  const updateNetworkStatus = () => {
    setIsNetwork(navigator.onLine);
  };

  useEffect(() => {
    addEventListener("offline", updateNetworkStatus);
    addEventListener("online", updateNetworkStatus);

    return () => {
      removeEventListener("online", updateNetworkStatus);
      removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  return isNetwork;
};

export default useNetwork;
