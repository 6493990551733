import React from "react";
import propTypes from "prop-types";
import "./confirmDialog.css";
import Button from "../BUTTON/button";

const ConfirmDialog = ({
  visible,
  title,
  messageHeading,
  message,
  confirmText = "Yes",
  cancelText = "No",
  confirmHandler,
  cancelHandler,
}) => {
  cancelHandler;
  return (
    <div className={`dialog-overlay ${visible && "dialog-overlay--visible"}`}>
      <div className={`dialog-content ${visible && "dialog-content--visible"}`}>
        <div className="dialog-header">
          <label>{title}</label>
        </div>
        <p className="dialog-body">
          {messageHeading && (
            <>
              {" "}
              {messageHeading} <br />{" "}
            </>
          )}
          {message}
        </p>

        <div className="dialog-footer">
          <Button
            innerTextBtn={cancelText}
            btnType="button"
            btnCustomClassName="btn--grey"
            handleSubmitAction={cancelHandler}
          />
          <Button
            innerTextBtn={confirmText}
            btnType="button"
            handleSubmitAction={confirmHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  confirmHandler: propTypes.func.isRequired,
  cancelHandler: propTypes.func.isRequired,
  visible: propTypes.bool.isRequired,
  title: propTypes.string,
  message: propTypes.string.isRequired,
  confirmText: propTypes.string,
  cancelText: propTypes.string,
  messageHeading: propTypes.string,
};
