import { MdSignalWifiStatusbarConnectedNoInternet1 } from "react-icons/md";
import { Logo } from "../../assets";
import Footer from "../../components/FOOTER/Footer";
import "./noNetworkConnection.css";

const NoNetworkConnection = () => {
  return (
    <div className="no-network">
      <div className="message-banner">
        <img src={Logo} alt="logo" />

        <div className="success-message">
          <MdSignalWifiStatusbarConnectedNoInternet1 size={120} color="red" />
          <h1>You have no internet connection </h1>
          <p>Make sure your mobile internet connection is ON, or connect to a public WIFI</p>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default NoNetworkConnection;
