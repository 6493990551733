import { LOGIN_ACTION, TOGGLE_LOGOUT } from "./loginTypes";

export const logUserIn = (payload = {}) => {
  return {
    type: LOGIN_ACTION,
    payload,
  };
};

export const toggleLogout = (payload = {}) => {
  return {
    type: TOGGLE_LOGOUT,
    payload,
  };
};

