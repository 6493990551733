import { useState } from "react";
import propTypes from "prop-types";
import Alert from "../../../../components/ALERT/Alert";
import Button from "../../../../components/BUTTON/button";
import Input from "../../../../components/INPUT/input";
import "./passCodeForm.css";

const PassCodeForm = ({ submitHandler, loading, errorMessage }) => {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    submitHandler(state);
  };
  return (
    <form onSubmit={handleSubmit} className="pass-code-form">
      <div className="form-input-ctn">
        <Input
          name="pass_code"
          htmlFor="pass_code"
          innerTextLabel="Enter verification code sent along with the invite"
          isRequired={true}
          handleChange={handleChange}
          value={state.pass_code}
          placeHolder="Pass code"
        />
      </div>

     
      {errorMessage && <Alert message={errorMessage} type="danger" />}
      <Button
        innerTextBtn="Proceed"
        type="submit"
        // handleSubmitAction={handleSubmit}
        loading={loading}
        disabled={loading}
      />
    </form>
  );
};

export default PassCodeForm;
PassCodeForm.propTypes = {
  submitHandler: propTypes.func.isRequired,
  loading: propTypes.bool,
  errorMessage: propTypes.string,
};
