import { FaRegSadTear } from "react-icons/fa";
import { Logo } from "../../assets";
import Footer from "../../components/FOOTER/Footer";
import "./declineInviteSuccess.css";

const DeclineInviteSuccess = () => {
  return (
    <div className="decline-invite-success">
      <div className="message-banner">
        <img src={Logo} alt="logo" />

        <div className="success-message" >
          <FaRegSadTear size={120} color="green" />
          <h1>You have successfully Declined the invitation</h1>

        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default DeclineInviteSuccess;
