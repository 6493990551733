import { GET_SETTINGS } from "./settingsType";

const INITIAL_STATE = {
  settings: {},
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };

    default:
      return { ...state };
  }
};

export default settingsReducer;
