import React from "react";
import PropTypes from "prop-types";
import "./button.css";

function Button({
  handleSubmitAction,
  btnCustomClassName = "",
  innerTextBtn = "",
  type = "submit",
  disabled = false,
  loading = false,
}) {
  return (
    <>
      <button
        type={type}
        onClick={handleSubmitAction}
        className={`btn ${btnCustomClassName} ${disabled && "btn-disabled"}`}
        disabled={disabled}
      >
        {loading ? "Loading..." : innerTextBtn}
      </button>
    </>
  );
}

Button.propTypes = {
  btnCustomClassName: PropTypes.string,
  innerTextBtn: PropTypes.string,
  handleSubmitAction: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
