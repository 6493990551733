import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./rootReducer";
const checkLogEnv =
  process.env.NODE_ENV == "production"
    ? composeWithDevTools(applyMiddleware(thunk))
    : composeWithDevTools(applyMiddleware(thunk, logger));

const store = createStore(rootReducer, checkLogEnv);

export default store;
