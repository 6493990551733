export const setAccessToken = (token) => {
  localStorage.access_tk = token;
};
export const getAccessToken = () => localStorage.access_tk;

export const clearAccessToken = () => {
  localStorage.removeItem("access_tk");
};
export const setCurrentUser = (user) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
};
export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("currentUser"));
};
export const clearCurrentUser = () => {
  localStorage.removeItem("currentUser");
};


export const setLastPathname = (path) => {
  localStorage.setItem("lastPath", path);
};
export const getLastPathname = () => {
  return localStorage.getItem("lastPath");
};