import propTypes from "prop-types";
import { Logo } from "../../assets";
import Button from "../BUTTON/button";
import "./messageBanner.css";
const MessageBanner = ({
  heading,
  greeting,
  message,
  note,
  actBtnText,
  actBtnHandler,
}) => {
  return (
    <div className="message-banner">
      <img src={Logo} alt="logo" />
      <div>
        <h1> {heading} </h1>
        <p> {greeting} </p>
        <p> {message} </p>
        <Button
          innerTextBtn={actBtnText}
          handleSubmitAction={actBtnHandler}
        />{" "}
        {note && <small> {note} </small>}
      </div>
    </div>
  );
};

export default MessageBanner;
MessageBanner.propTypes = {
  heading: propTypes.string.isRequired,
  greeting: propTypes.string.isRequired,
  message: propTypes.string.isRequired,
  actBtnText: propTypes.string.isRequired,
  actBtnHandler: propTypes.func.isRequired,
  note: propTypes.string,
};
