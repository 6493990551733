import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  // useHistory,
  
} from "react-router-dom";
import {
  ACCEPT_INVITE_SUCCESS_ROUTE,
  DECLINE_INVITE_SUCCESS_ROUTE,
  EMAIL_VERIFICATION_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  FORGOT_PASSWORD_SUCCESS_ROUTE,
  GUEST_INVITES_ROUTE,
  GUEST_PASS_ROUTE,
  LOGIN_ROUTE,
  PASSWORD_RESET_ROUTE,
  PASSWORD_RESET_SUCCESS_ROUTE,
  RESET_PASSWORD_ROUTE,
  PRIVACY_POLICY
} from ".";
import Loader from "../components/LOADER/Loader";
// import { getLastPathname } from "../util/localStorarge";
import AcceptInviteSuccess from "../views/ACCEPTINVITESUCCESS/AcceptInviteSuccess";
import GuestInvitation from "../views/APPROVEPROPSER/GuestInvitation";
import DeclineInviteSuccess from "../views/DECLINEINVITESUCCESS/DeclineInviteSuccess";
import EmailVerification from "../views/EMAILVERIFICATION/EmailVerification";
import ForgotPasswordSuccess from "../views/FORGOTPASSWORDSUCCESS/ForgotPasswordSuccess";
import GuestPass from "../views/GUESTPASS/GuestPass";
import PasswordReset from "../views/PASSWORDRESET/PasswordResrt";
import PasswordResetSuccess from "../views/PASSWORDRESETSUCCESS/PasswordResetSuccess";
import PrivacyPolicy from "../views/PRIVACYPOLICY/PrivacyPolicy";
import RestrictedRoute from "./RestrictedRoute";

const Login = lazy(() => import("../views/LOGIN/Login"));
const ForgotPassword = lazy(() =>
  import("../views/FORGOTPASSWORD/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../views/RESETPASSWORD/ResetPassword")
);
const Dashboard = lazy(() => import("../layout/DASHBOARD/Dashboard"));

const GlobalRouter = () => {
  const { isAuthenticated } = useSelector(({ login }) => login);
  const location = useLocation();
  const match = useRouteMatch();
  // const history = useHistory();

  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  return (
    <Suspense
      fallback={
        <div style={style}>
          <Loader />
        </div>
      }
    >
      <Switch>
        <Route exact path={LOGIN_ROUTE} component={Login} />
        <Route exact path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
        <Route exact path={RESET_PASSWORD_ROUTE} component={ResetPassword} />
        <Route
          exact
          path={FORGOT_PASSWORD_SUCCESS_ROUTE}
          component={ForgotPasswordSuccess}
        />
        <Route
          exact
          path={EMAIL_VERIFICATION_ROUTE}
          component={EmailVerification}
        />
        <Route exact path={PASSWORD_RESET_ROUTE} component={PasswordReset} />
        <Route
          exact
          path={PASSWORD_RESET_SUCCESS_ROUTE}
          component={PasswordResetSuccess}
        />
        <Route exact path={GUEST_INVITES_ROUTE} component={GuestInvitation} />
        <Route exact path={GUEST_PASS_ROUTE} component={GuestPass} />
        <Route
          exact
          path={ACCEPT_INVITE_SUCCESS_ROUTE}
          component={AcceptInviteSuccess}
        />
        <Route
          exact
          path={DECLINE_INVITE_SUCCESS_ROUTE}
          component={DeclineInviteSuccess}
        />
         <Route
          exact
          path={PRIVACY_POLICY}
          component={PrivacyPolicy}
        />
        <RestrictedRoute
          path={`${match.url}`}
          isAuthenticated={isAuthenticated}
          location={location}
          component={Dashboard}
        />
      </Switch>
    </Suspense>
  );
};
export default GlobalRouter;
