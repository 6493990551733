import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Logo } from "../../assets";
import Button from "../../components/BUTTON/button";
import Footer from "../../components/FOOTER/Footer";
import Loader from "../../components/LOADER/Loader";
import { BASE_URL } from "../../configs/baseUrl";
import Jumbotron from "../../components/JUMBOTRON/Jumbotron";
// import { handleGetRequest } from "../../configs/axios/axios";
import Modal from "../../components/MODAL/Modal";
import {
  ACCEPT_INVITE_SUCCESS_ROUTE,
  DECLINE_INVITE_SUCCESS_ROUTE,
} from "../../routes";
import { GET_INVITE_DETAILS_API } from "../../constants/endpoints/bookings";

import "./guestInvitation.css";
import PassCodeForm from "./components/familyForm/PassCodeForm";
import { formatDate, get12HoursTimeFromDateTime } from "../../util/helpers";

const GuestInvitation = () => {
  const [loading, setLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [showDecline, setShowDecline] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAccept, setShowAccept] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [inviteDetails, setInviteDetails] = useState(null);
  const [inviteStatus, setInviteStatus] = useState("PENDING");

  const history = useHistory();

  useEffect(() => {
    (async () => {
      // get query string
      const queryString = window.location.search;
      // get query string parameters
      const urlParams = new URLSearchParams(queryString);
      const shareLink = urlParams.get("share_link");
      setFetchLoading(true);
      try {
        if (shareLink) {
          const { data } = await axios.get(
            `${BASE_URL}${GET_INVITE_DETAILS_API}${shareLink}`
          );
          if (data.status) {
            console.log(data.data);
            setInviteDetails(data?.data);
            setInviteStatus(data?.data?.guest?.booking_guest_status);
            setShareLink(shareLink);
          }
        }
      } finally {
        setFetchLoading(false);
      }
    })();
  }, []);

  const handleAccept = async ({ pass_code }) => {
    setErrorMessage("");
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${BASE_URL}/openbooking/acceptinvitation/${shareLink}?code=${pass_code}`
      );
      if (data.status) {
        history.push(ACCEPT_INVITE_SUCCESS_ROUTE);
      } else setErrorMessage(data?.message);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async ({ pass_code }) => {
    setErrorMessage("");
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${BASE_URL}/openbooking/rejectinvitation/${shareLink}?code=${pass_code}`
      );
      if (data.status) {
        history.push(DECLINE_INVITE_SUCCESS_ROUTE);
      } else setErrorMessage(data?.message);
    } catch (error) {
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };
  const toggleDecline = () => {
    setErrorMessage("");
    setShowDecline(!showDecline);
  };
  const toggleAccept = () => {
    setErrorMessage("");
    setShowAccept(!showAccept);
  };
  const getInviteDetails = (inviteDetails) => {
    if (inviteDetails.event)
      return (
        <>
          <p className="greetings">
            {" "}
            Hello {inviteDetails.guest?.name_on_booking.split(" ")[0]},{" "}
          </p>
          <p className="info">
            {" "}
            You have been invited to MÌLÍKÌ as a guest of{" "}
            {inviteDetails.patron?.first_name} {inviteDetails.patron?.last_name}{" "}
            on {formatDate(inviteDetails.guest?.start_date_time)}.
          </p>
          <div className="table-resp">
            <table>
              <tbody>
                <tr>
                  <td>Booking Date</td>
                  <td>{formatDate(inviteDetails.guest?.start_date_time)}</td>
                </tr>
                <tr>
                  <td>Booking Time</td>
                  <td>
                    {get12HoursTimeFromDateTime(
                      inviteDetails.guest?.start_date_time
                    )}{" "}
                    -{" "}
                    {get12HoursTimeFromDateTime(
                      inviteDetails.guest?.end_date_time
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Booking Location </td>
                  <td>
                    {inviteDetails.event
                      ? inviteDetails.event?.event_space_name
                      : inviteDetails?.space?.name}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    if (inviteDetails.space)
      return (
        <>
          <p className="greetings">
            {" "}
            Hello {inviteDetails.guest?.name_on_booking.split(" ")[0]},{" "}
          </p>
          <p className="info">
            {" "}
            You have been invited to MÌLÍKÌ as a guest of{" "}
            {inviteDetails.patron?.first_name} {inviteDetails.patron?.last_name}{" "}
            on {formatDate(inviteDetails.guest?.start_date_time)}
          </p>
          <div className="table-resp">
            <table>
              <tbody>
                <tr>
                  <td>Booking Date</td>
                  <td>{formatDate(inviteDetails.guest?.start_date_time)}</td>
                </tr>
                <tr>
                  <td>Booking Time</td>
                  <td>
                    {get12HoursTimeFromDateTime(
                      inviteDetails.guest?.start_date_time
                    )}{" "}
                    -{" "}
                    {get12HoursTimeFromDateTime(
                      inviteDetails.guest?.end_date_time
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Booking Location </td>
                  <td>
                    {inviteDetails.event
                      ? inviteDetails.event?.event_space_name
                      : inviteDetails?.space?.name}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );

    return (
      <>
        <p className="greetings">
          {" "}
          Hello {inviteDetails.guest?.name_on_booking.split(" ")[0]},{" "}
        </p>
        <p className="info">
          {" "}
          You have been invited to MÌLÍKÌ as a guest of{" "}
          {inviteDetails.patron?.first_name} {inviteDetails.patron?.last_name} {" "}
          on {formatDate(inviteDetails.guest?.start_date_time)}.
        </p>
      </>
    );
  };
  return (
    <div className="approve-proposer">
      <div className="message-banner">
        <img src={Logo} alt="logo" />
        {fetchLoading ? (
          <Loader />
        ) : (
          <div>
            {!inviteDetails ? (
              <Jumbotron message="No details found for this invite" />
            ) : (
              <>
                {inviteStatus !== "PENDING" ? (
                  <Jumbotron
                    message={`This invitation has been ${inviteStatus?.toLocaleLowerCase()}`}
                  />
                ) : (
                  <>
                    {getInviteDetails(inviteDetails)}

                    <div className="actn-btns">
                      <Button
                        innerTextBtn="ACCEPT INVITE"
                        handleSubmitAction={toggleAccept}
                      />
                      <Button
                        innerTextBtn="DECLINE INVITE"
                        handleSubmitAction={toggleDecline}
                        btnCustomClassName="btn--outline"
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="footer">
        <Footer />
      </div>
      {showAccept && (
        <Modal
          visible={showAccept}
          title="Enter verification code to accept invite"
          closeHandler={toggleAccept}
          showHeader
        >
          <PassCodeForm
            loading={loading}
            errorMessage={errorMessage}
            submitHandler={handleAccept}
          />
        </Modal>
      )}
      {showDecline && (
        <Modal
          visible={showDecline}
          title="Enter verification code to decline invite"
          closeHandler={toggleDecline}
          showHeader
        >
          <PassCodeForm
            loading={loading}
            errorMessage={errorMessage}
            submitHandler={handleDecline}
          />
        </Modal>
      )}
    </div>
  );
};

export default GuestInvitation;
