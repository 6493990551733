import propTypes from "prop-types";
import { FiAlertTriangle } from "react-icons/fi";
import "./alert.css";
const Alert = ({ type, message }) => {
  return (
    <div className={`alert alert--${type}`}>
      <FiAlertTriangle />
      <p>{message}</p>
    </div>
  );
};

export default Alert;
Alert.propTypes = {
  type: propTypes.string,
  message: propTypes.string,
};
