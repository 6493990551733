import { Route, useHistory } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import "./style.css";
import GlobalRouter from "./routes/GlobalRouter";
import { Toaster } from "react-hot-toast";
import { queryClient } from "./configs/reactQuery";
import useNetwork from "./hooks/useNetwork";
import NoNetworkConnection from "./components/NONETWORK/NoNetworkConnection";
import ConfirmDialog from "./components/CONFIRMDIALOG/ConfirmDialog";
import { LOGIN_ROUTE } from "./routes";
import {
  clearAccessToken,
  clearCurrentUser,
  setLastPathname,
} from "./util/localStorarge";
import { useDispatch, useSelector } from "react-redux";
import { toggleLogout } from "./views/LOGIN/redux/loginAction";
import { useEffect } from "react";

function App() {
  const isNetworkConnection = useNetwork();
  const login = useSelector(({ login }) => login);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    clearCurrentUser();
    clearAccessToken();
    toggleLogoutConfirm();

    setLastPathname("/")
    history.push(LOGIN_ROUTE);
  };
  const toggleLogoutConfirm = () => {
    dispatch(toggleLogout());
  };


  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      const url = window.location.pathname;
      if (url) {
        setLastPathname(url);
      }
    });
    return () => {
      window.removeEventListener("beforeunload", () => {
        setLastPathname("/")
      });
    };
  }, []);


  if (!isNetworkConnection) return <NoNetworkConnection />;
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Route component={GlobalRouter} />
        <Toaster />
        {login?.showLgoutModal && (
          <ConfirmDialog
            visible={login?.showLgoutModal}
            message="Are you sure you want to logout?"
            confirmText="Yes"
            cancelText="No"
            cancelHandler={toggleLogoutConfirm}
            confirmHandler={handleLogout}
          />
        )}
      </div>
    </QueryClientProvider>
  );
}

export default App;
