import { Logo } from "../../assets";
import "./loader.css";
const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-shadow">
        <img src={Logo} alt="loader" />
      </div>
      <h2>MÌLÍKÌ</h2>
      <p>loading...</p>
    </div>
  );
};

export default Loader;