import { BsPatchCheckFill } from "react-icons/bs";
import { Logo } from "../../assets";
import Footer from "../../components/FOOTER/Footer";
import "./acceptInviteSuccess.css";

const AcceptInviteSuccess = () => {
  return (
    <div className="accept-invite-success">
      <div className="message-banner">
        <img src={Logo} alt="logo" />

        <div className="success-message" >
          <BsPatchCheckFill size={120} color="green" />
          <h1>You have successfully accepted the invitation</h1>
          <span>A guest pass will be sent to you shortly.</span>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default AcceptInviteSuccess;
