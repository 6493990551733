// import axios from "axios";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Logo } from "../../assets";
import Footer from "../../components/FOOTER/Footer";
import Loader from "../../components/LOADER/Loader";
// import { BASE_URL } from "../../configs/baseUrl";
import Jumbotron from "../../components/JUMBOTRON/Jumbotron";
import { handleGetRequest } from "../../configs/axios/axios";
import { GET_INVITE_DETAILS_API } from "../../constants/endpoints/bookings";

import "./guestPass.css";
import { formatDate, get12HoursTimeFromDateTime } from "../../util/helpers";

const GuestPass = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [inviteDetails, setInviteDetails] = useState(null);
  const [shareLink, setShareLink] = useState("");


  useEffect(() => {
    (async () => {
      // get query string
      const queryString = window.location.search;
      // get query string parameters
      const urlParams = new URLSearchParams(queryString);
      const shareLink = urlParams.get("share_link");
      setFetchLoading(true);
      try {
        if (shareLink) {
          const data = await handleGetRequest(
            `${GET_INVITE_DETAILS_API}${shareLink}`
          );
          if (data.status) {
            setInviteDetails(data?.data);
            setShareLink(shareLink);
          }
        }
      } finally {
        setFetchLoading(false);
      }
    })();
  }, []);
 
  return (
    <div className="guest-pass">
      <div className="message-banner">
        <img src={Logo} alt="logo" />
        {fetchLoading ? (
          <Loader />
        ) : (
          <div>
            {!inviteDetails ? (
              <Jumbotron />
            ) : (
              <>
                <p className="greetings">
                  {" "}
                  Hello {inviteDetails.guest?.name_on_booking},{" "}
                </p>
                <p className="info">
                  {" "}
                  You have been invited by {
                    inviteDetails.patron?.first_name
                  }{" "}
                  {inviteDetails.patron?.last_name} to attend a booking at
                  MILIKI. See basic information about the booking below.{" "}
                </p>
                <div className="table-resp">
                <table>
                    <tbody>
                      <tr>
                        <td>Booking Date</td>
                        <td>
                          {formatDate(inviteDetails.guest?.start_date_time)}
                        </td>
                      </tr>
                      <tr>
                        <td>Booking Time</td>
                        <td>
                          {get12HoursTimeFromDateTime(inviteDetails.guest?.start_date_time)} -{" "}
                          {get12HoursTimeFromDateTime(inviteDetails.guest?.end_date_time)}
                        </td>
                      </tr>
                      <tr>
                        <td>Booking Location </td>
                        <td>
                          {inviteDetails.event
                            ? inviteDetails.event?.event_space_name
                            : inviteDetails?.space?.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3>Scan QR Code</h3>
                <div className="qr-code">
                  <QRCode value={shareLink} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="footer">
        <Footer />
      </div>
      
    </div>
  );
};

export default GuestPass;
