import propTypes from "prop-types";
import "./jumbotron.css";
const Jumbotron = ({ message }) => {
  return (
    <div className="jumbotron">
      <p>{message}</p>
    </div>
  );
};

export default Jumbotron;
Jumbotron.propTypes = {
  message: propTypes.string.isRequired,
};
