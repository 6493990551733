import React from "react";
import propTypes from "prop-types";
import "./modal.css";

const Modal = ({
  visible,
  title,
  children,
  closeHandler,
  showHeader = false,
}) => {
  return (
    <div className={`modal-overlay ${visible && "modal-overlay--visible"}`}>
      <div className={`modal-content ${visible && "modal-content--visible"}`}>
        {showHeader && (
          <div className="modal-header">
            <h4>{title}</h4>
            <span onClick={closeHandler} className="closeWrap">
              <p>x</p>
            </span>
          </div>
        )}
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  children: propTypes.any,
  visible: propTypes.bool.isRequired,
  closeHandler: propTypes.func,
  showHeader: propTypes.bool.isRequired,
  title: propTypes.string,
};
