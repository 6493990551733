import propTypes from "prop-types";
import "./input.css";

function InputText({
  htmlFor,
  name,
  labelCustomClass,
  innerTextLabel,
  inputCustomClass,
  placeHolder,
  handleChange,
  value,
  InputType,
  isRequired,
  pattern = null,
  title = "",
  errorLabel = "",
  borderClass = "",
  handleBlur,
  isDisabled = false,
  min = 0,
  max = 250,
  showLenght = false,
}) {
  const blurHandler = (e) => {
    if (handleBlur) handleBlur(e);
  };
  return (
    <>
     <label htmlFor={htmlFor} className={`${labelCustomClass} input-wrapper-label`}>
          {innerTextLabel}
        </label>
      <div className={`input-wrapper ${borderClass}`}>
       
        <input
          autoComplete="off"
          autoCorrect="off"
          className={`${inputCustomClass} input-text `}
          onChange={handleChange}
          onBlur={blurHandler}
          name={name}
          value={value || ""}
          type={InputType}
          placeholder={placeHolder}
          required={isRequired}
          pattern={pattern}
          title={title}
          disabled={isDisabled}
          maxLength={max}
          minLength={min}
        />
      </div>
      <div className="row">
        {errorLabel && <label className="error-label">{errorLabel}</label>}
        {showLenght && (
          <span className="length">
            {min}-{max} chars
          </span>
        )}
      </div>
    </>
  );
}
InputText.propTypes = {
  htmlFor: propTypes.string,
  name: propTypes.string,
  labelCustomClass: propTypes.string,
  innerTextLabel: propTypes.string,
  inputCustomClass: propTypes.string,
  placeHolder: propTypes.string,
  handleChange: propTypes.func.isRequired,
  value: propTypes.string,
  InputType: propTypes.string,
  isRequired: propTypes.bool,
  pattern: propTypes.string,
  title: propTypes.string,
  errorLabel: propTypes.string,
  borderClass: propTypes.string,
  isDisabled: propTypes.bool,
  showLenght: propTypes.bool,
  min: propTypes.number,
  max: propTypes.number,
  handleBlur: propTypes.func
};
export default InputText;
