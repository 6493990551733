import Footer from "../../components/FOOTER/Footer";
import MessageBanner from "../../components/MESSAGEBANNER/MessageBanner";
import "./emailVerification.css";

const EmailVerification = () => {
  const handleVerification = () => {};
  return (
    <div className="email-verification">
      <MessageBanner
        heading="VERIFY YOUR EMAIL"
        greeting="Hello John,"
        message="You have successfully created a miliki account, to get started you have to verify your account"
        note="If you did not sign up for this account, you can ignore this email and the account will be deleted."
        actBtnText="VERIFY EMAIL"
        actBtnHandler={handleVerification}
      />
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default EmailVerification;
