export const DASHBOARD_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const BOOKINGS_ROUTE = "/bookings";
export const TRANSACTIONS_ROUTE = "/transactions";
export const EVENTS_ROUTE = "/events";
export const PATRONS_ROUTE = "/patrons";
export const NON_PATRONS_ROUTE = "/associate-patrons";
export const ADMINS_ROUTE = "/admins";
export const BROADCAST_ROUTE = "/broadcast";
export const SETTINGS = "/settings";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const ROLES_ROUTE = "/roles";
export const USER_ROUTE = "/user";
export const CHANGE_PASSWORD_ROUTE = "/change-password";
export const FORGOT_PASSWORD_SUCCESS_ROUTE = "/forgot-password-success";
export const SPACES_ROUTE = "/lounges";
export const EMAIL_VERIFICATION_ROUTE = "/email-verification";
export const PASSWORD_RESET_ROUTE = "/password-reset";
export const PASSWORD_RESET_SUCCESS_ROUTE = "/password-reset-success";
export const APPROVE_PROPOSER_ROUTE = "/approve-proposer";
export const GUEST_INVITES_ROUTE = "/guest-pass";
export const ACCEPT_INVITE_SUCCESS_ROUTE = "/accept-invite-success";
export const DECLINE_INVITE_SUCCESS_ROUTE = "/decline-invite-success";
export const GUEST_PASS_ROUTE = "/miliki-guest-pass";
export const PRIVACY_POLICY = "/privacy-policy"
