// import { getAccessToken } from "../../util/localStorarge";
//import { setAuthToken } from "../../util/setToken";
import API from "./axiosDefaultConfig";

export const handleGetRequest = async (payload) => {
//   const token = getAccessToken();
  // setAuthToken(token)
  try {
    const { data } = await API.get(payload);
    return data;
  } catch (err) {
    console.log("");
    throw err;
  }
};

export const handlePostRequest = async (path, payload) => {
//   const token = getAccessToken();
  // setAuthToken(token)
  try {
    const { data } = await API.post(`${path}`, payload);
    return data;
  } catch (err) {
    console.log("");
    throw err;
  }
};
export const handleDeleteRequest = async (payload) => {
//   const token = getAccessToken();
  // setAuthToken(token)
  try {
    const { data } = await API.delete(`${payload}`);
    return data;
  } catch (err) {
    console.log("");
    throw err;
  }
};
